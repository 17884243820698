import { configureStore } from '@reduxjs/toolkit';
import cartReducer from './cart';
import orderReducer from './order';

export default configureStore({
  reducer: {
    cart: cartReducer,
    order: orderReducer,
  }
});
