import {formatPrice} from '../helpers/helpers';
import {useEffect, useState} from 'react';
import {apiClient} from '../api/client';
import {Col, Image, Row} from 'react-bootstrap';

export default function CartPayment({body}) {
  const [status, setStatus] = useState('');

  // const cancelPayment = () => {
  //
  // };

  useEffect(() => {
    const interval = setInterval(async() => {
        const payment = await apiClient.get(`/payment/${body.reference}`);
        if (null !== payment.cancelledByUserAt) {
          setStatus(<h5 className="text-danger">Has cancelado este pago</h5>)
          clearInterval(interval);
        } else if (null !== payment.errorAt) {
          setStatus(<h5 className="text-danger">Ha habido un error con el pago ({payment.errorReason})</h5>)
          clearInterval(interval);
        } else if (null !== payment.paidAt) {
          setStatus(<h5 className="text-success">¡Pedido completado!<br />En breve te lo llevaremos a tu mesa</h5>)
          clearInterval(interval);
        }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Row className="total-payment">
        <Col className="text-center">Saldo restante: {formatPrice(body.balance)}</Col>
        <Col className="text-center">Cantidad a pagar: {formatPrice(body.amount)}</Col>
      </Row>
      <hr/>
      <Row>
        <Col xs={6} className="text-center">
          <h6>Escanear el siguiente QR para terminar el pago:</h6>
          <Image className="mb-5" src={`https://secure.monei.com/payments/${body.paymentId}/qr?format=svg&size=300`} width="300" height="300" style={{borderRadius: '10px', marginTop:'15px'}}/>
        </Col>
        <Col xs={6} className="text-center align-self-center">
          {'' === status && <h5>Esperando pago...</h5>}
          {'' !== status && <>{status}</>}
        </Col>
      </Row>

      {/*{'FAILED' === status && <p>Ha fallado el pago...</p>}*/}
      {/*{'SUCCEEDED' === status && <p>Pago completado</p>}*/}
    </>
  )
}
