import {Container, Button, Image, Stack} from 'react-bootstrap';
import {useEffect} from 'react';
// import {apiClient} from './api/client';
import {Link, useNavigate} from 'react-router-dom';
import {HEADER_TABLE_KEY} from './api/client';

export default function App() {
  const loading = false;
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);
  // const [image, setImage] = useState();

  // const fetch = async () => {
    // const response = await apiClient.get('/screen');
    // setImage(response.url);
    // setLoading(false);
  // }
  const checkTable = () => {
    if (!localStorage.getItem(HEADER_TABLE_KEY)) {
      navigate('/start')
    }
  };

  useEffect(() => {
    checkTable();
    // fetch();
  }, []);

  return (
    <Container fluid className="startContainer bgImage">
      {loading
        ? <p>Cargando...</p>
        :
        <Stack gap={5} className="w-50 vh-100 m-auto justify-content-center text-center">
          <Link to="/catalog/1"><Image width={150} src="images/logo.svg" className='logo' /></Link>
          <h1>¡BIENVENIDO A <strong>LAB theCLUB!</strong></h1>
          <Link to="/catalog/1"><Button size="lg">PULSA PARA ENTRAR</Button></Link>
        </Stack>
      }
    </Container>
  );
}
