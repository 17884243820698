import {Col, Image, Stack} from 'react-bootstrap';
import {Link, NavLink, useParams} from 'react-router-dom';
import WineBottles from '../images/WineBottles';
// import Drink from '../images/Drink';
import Cutlery from '../images/Cutlery';
import File from '../images/File';
import {TABLE_NAME} from '../../api/client';

export default function Sidebar() {
  const params = useParams();

  return (
    <Col className="sidebar">
      <Stack gap={3}>
        <Link to="/" className="logo"><Image width="100%" src="/images/logo.png"/></Link>
        <NavLink to="/catalog/1">
          <WineBottles/>
        </NavLink>
        {/*<NavLink to="/catalog/2">*/}
        {/*  <Drink/>*/}
        {/*</NavLink>*/}
        <NavLink to="/catalog/3">
          <Cutlery/>
        </NavLink>
        <div className="mt-auto last-element">
          <NavLink to={`/catalog/${params.groupId}/history`}>
            <File/>
          </NavLink>
          <h4 className="text-center text-secondary mt-2">MESA {localStorage.getItem(TABLE_NAME)}</h4>
        </div>
      </Stack>
    </Col>
  )
}
