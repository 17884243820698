import {Col, Image, Row} from 'react-bootstrap';
import {removeElement} from '../../store/cart';
import {useDispatch} from 'react-redux';

export default function Item({element, idx}) {
  const dispatch = useDispatch();

  const removeElementFromCart = (idx) => {
    dispatch(removeElement(idx));
  };

  return (
    <Row className="mb-3">
      <Col xs={2}><Image fluid src={`data:image/jpeg;base64, ${element.product.image}`}/></Col>
      <Col xs={8}>
        <h6>{element.product.name}</h6>
        <h5>{element.product.priceFormatted}</h5>
      </Col>
      <Col xs={2}><h5 onClick={() => removeElementFromCart(idx)}>X</h5></Col>
      <Col xs={{ span: 8, offset: 2 }}>
        <h6 className="text-secondary">
          {Object.values(element.additionsSummary).map((summary, idx) => {
            return <><span key={idx}>{summary.sum} x {summary.name}</span><br/></>
          })}
        </h6>
      </Col>
    </Row>
  )
}
