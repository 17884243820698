import {createBrowserRouter} from 'react-router-dom';
import App from '../App';
import React from 'react';
import Catalog from '../pages/Catalog';
import Category from '../pages/Category';
import Product from '../pages/Product';
import History from '../pages/History';
import Start from '../pages/Start';
import Cart from '../pages/Cart';
import CartSummary from '../pages/CartSummary';
import CategoryEmpty from '../pages/CategoryEmpty';

export const router = createBrowserRouter([
  {
    path: '/start',
    element: <Start />,
  },
  {
    path: '/',
    element: <App />,
  },
  {
    path: 'catalog/:groupId',
    element: <Catalog />,
    children: [
      {
        children: [
          {
            index: true,
            element: <CategoryEmpty/>
          },
          {
            path: 'history',
            element: <History/>,
          },
          {
            path: 'category/:categoryId',
            element: <Category/>,
            children: [
              {
                path: 'product/:productId',
                element: <Product/>
              },
              {
                path: 'cart',
                element: <Cart/>
              },
              {
                path: 'cart-summary',
                element: <CartSummary/>
              }
            ]
          }
        ]
      }
    ],
  },
]);
