export function formatPrice(price) {
  const EUR = new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR'
  })

  return EUR.format(price / 100);
}

export function formatDate(timestamp) {
  const date = new Date(timestamp * 1000);

  return date.toLocaleString();
}
