import {Container, Row, Col} from 'react-bootstrap';
import {Fragment, useEffect, useState} from 'react';
import {apiClient} from '../api/client';
import {formatPrice, formatDate} from '../helpers/helpers';
import Loading from '../components/common/Loading';

export default function History() {
  const [loading, setLoading] = useState(true);
  const [ticket, setTicket] = useState();

  const fetch = async () => {
    setLoading(true);

    const responseTicket = await apiClient.get('/ticket');
    setTicket(responseTicket);

    setLoading(false);
  }

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className="history">
      {loading
        ? <Loading/>
        :
        <>
          <Row className="balance">
            <Col xs={{span: 4, offset: 4}}><h5 className={0 === ticket.lines.length ? 'text-center' : ''}>Saldo en mesa: {formatPrice(ticket?.balance)}</h5></Col>
          </Row>
          {0 === ticket.lines.length && <div className="history-unit">
            <Row className="unit-header">
              <Col>
                <h5>NO TIENES PEDIDOS TODAVÍA</h5>
              </Col>
            </Row>
          </div>}
          {ticket.lines.map((line, idxLine) => {
            return (
              <div className="history-unit" key={idxLine}>
                <Row className="unit-header">
                  <Col>
                    <h5>PEDIDO: #{line.orderReference}</h5>
                  </Col>
                  <Col>
                    <h5>{formatDate(line.createdAt)}</h5>
                  </Col>
                  <Col>
                    <h5>TOTAL: {line.totalFormatted}</h5>
                  </Col>
                  <Col xs={12}><hr/></Col>
                </Row>
                {line.products.map((product, idxProduct) => {
                  return (
                    <Row className="unit-line" key={idxProduct}>
                      <Col xs={4}>
                        <h5>{product.name} ({product.priceFormatted})</h5>
                      </Col>
                      <Col xs={8}>
                        {product.children.length > 0 && <h6 className="text-secondary">
                          {product.children.map((child, idxChild) => {
                            return <span key={idxChild}>
                            {child.name}
                              {(idxChild+1) < product.children.length && <>, </>}
                          </span>
                          })}
                        </h6>}
                      </Col>
                    </Row>
                  )
                })}
              </div>
            )
          })}
        </>
      }
      </div>
  );
}
