import {Row, Col, Modal, Offcanvas} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import {apiClient} from '../api/client';
import {useMatch, useNavigate, useOutlet, useParams} from 'react-router-dom';
import ProductCard from '../components/catalog/ProductCard';
import Loading from '../components/common/Loading';

export default function Category() {
  const {categoryId} = useParams();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const outlet = useOutlet();
  const navigate = useNavigate();
  const matchProduct = useMatch('/catalog/:groupId/category/:categoryId/product/:productId');
  const matchCart = useMatch('/catalog/:groupId/category/:categoryId/cart');
  const matchCartSummary = useMatch('/catalog/:groupId/category/:categoryId/cart-summary');

  const fetch = async () => {
    setLoading(true);
    const response = await apiClient.get(`/catalog/${categoryId}`);
    setProducts(response.products);
    setLoading(false);
  }

  useEffect(() => {
    fetch();
  }, [categoryId]);

  return (
      <Row>
        <Col>
          {loading
            ? <Loading/>
            : <Row className="justify-content-center">
              {products.map((product) => <ProductCard key={product.id} product={product}/> )}
            </Row>
          }
        </Col>
        {null !== outlet && null !== matchProduct &&
          <Modal dialogClassName="modal-dialog-centered" size="xl" show={true} onHide={() => navigate('../..', {relative: 'path'})}>
            {outlet}
          </Modal>
        }
        {null !== outlet && null !== matchCart &&
          <Offcanvas placement="end" size="xl" show={true} onHide={() => navigate('..', {relative: 'path'})}>
            {outlet}
          </Offcanvas>
        }
        {null !== outlet && null !== matchCartSummary &&
          <Modal dialogClassName="modal-dialog-center" size="xl" show={true} onHide={() => navigate('..', {relative: 'path'})}>
            {outlet}
          </Modal>
        }
      </Row>
  );
}
