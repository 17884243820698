import {Row, Col, Image, Button, Modal, Stack} from 'react-bootstrap';
import {Fragment, useEffect, useState} from 'react';
import {apiClient} from '../api/client';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {addToCart} from '../store/cart';
import Loading from '../components/common/Loading';

export default function Product() {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState();
  const [additionsSelected, setAdditionsSelected] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetch = async () => {
    setLoading(true);
    const response = await apiClient.get(`/catalog/${params.categoryId}/products/${params.productId}`);
    setProduct(response);
    setLoading(false);
  };

  const addAddition = (id) => {
    if (additionsSelected.length < 10) {
      const newAdditions = [...additionsSelected];
      newAdditions.push(id);
      setAdditionsSelected(newAdditions);
    }
  };

  const removeAddition = (id) => {
    const newAdditions = [...additionsSelected];
    const index = newAdditions.indexOf(id);
    if (-1 !== index) {
      newAdditions.splice(index, 1);
      setAdditionsSelected(newAdditions);
    }
  };

  const toCart = () => {
    const doToCart = () => {
      let additionsSummary = {};

      product.additionsGroups.forEach((group) => {
        group.products.forEach((product) => {
          if (!additionsSummary.hasOwnProperty(product.id)) {
            let sum = 0;
            additionsSelected.forEach((productId) => {
              if (productId === product.id) {
                sum++;
              }
            });

            if (sum > 0) {
              additionsSummary[product.id] = {name: product.name, sum};
            }
          }
        });
      });

      dispatch(addToCart({product, additionsSelected, additionsSummary}));
      navigate(`/catalog/${params.groupId}/category/${params.categoryId}/cart`);
    }

    if (additionsSelected.length < 10) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('No tienes todos los complementos añadidos, ¿seguro que quieres continuar?')) {
        doToCart()
      }
    } else {
      doToCart()
    }
  };

  const countSelected = (id) => {
    return additionsSelected.filter((additionId) => id === additionId).length;
  };

  useEffect(() => {
    fetch();
    setAdditionsSelected([]);
  }, [params.productId]);

  return (
    <Modal.Body className="product-detail">
      {loading
        ? <Loading/>
        : <>
          <Row>
            <Col xs={3}>
              <Stack gap={3}>
                <Image fluid src={`data:image/jpeg;base64, ${product.image}`}/>
                <p className="name">{product.name}</p>
                <p className="price">{product.priceFormatted}</p>
              </Stack>
            </Col>
            <Col xs={9}>
              <h2>ELIGE TUS COMPLEMENTOS</h2>
              {product.additionsGroups.map((group) => {
                return (
                  <Fragment key={group.id}>
                    <h5>Seleccionados: {additionsSelected.length}. Min: {group.min}. Max: {group.max}</h5>
                    <Row className="product-content">
                      {group.products.map((product) => {
                        return (
                          <Col className="mb-3" key={product.id} xs={3} xl={2}>
                            <Image fluid src={`data:image/jpeg;base64, ${product.image}`} onClick={() => addAddition(product.id)} />
                            <h6>{product.name} ({countSelected(product.id)}) {countSelected(product.id) > 0 && <span onClick={() => removeAddition(product.id)}>X</span>}</h6>
                          </Col>
                        )
                      })}
                    </Row>
                  </Fragment>
                )
              })}
            </Col>
          </Row>
        </>
      }
      <Row className="justify-content-between mt-4">
        <Col className="text-start">
          <Button variant="outline-primary" onClick={() => navigate(-1)}>VOLVER AL CATÁLOGO</Button>
        </Col>
        <Col className="text-end">
          <Button onClick={() => toCart()}>AÑADIR AL CARRO</Button>
        </Col>
      </Row>
    </Modal.Body>
  );
}
