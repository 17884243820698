const API_BASE_URI = process.env.REACT_APP_API_BASE_URI

export const HEADER_TABLE_KEY = 'table';
export const TABLE_NAME = 'table:name';
export const ERRORS = {
  SERVER: 'server',
  UNAUTHORIZED: 'unauthorized',
  VERSION_MINIMUM: 'version_minimum'
}

export const apiClient = {
  get(endpoint) {
    return request(endpoint, 'GET', {})
  },
  post(endpoint, payload) {
    return request(endpoint, 'POST', payload)
  }
}

async function request(endpoint, method, payload) {
  let options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'table': localStorage.getItem(HEADER_TABLE_KEY)
    }
  }

  if ('undefined' !== typeof payload && Object.keys(payload).length > 0) {
    options.body = JSON.stringify(payload)
  }

  // console.log(endpoint);
  const response = await fetch(`${API_BASE_URI}${endpoint}`, options)
  const status = response.status.toString()
  // console.log(endpoint, status);

  return await checkErrorsAndGetJson(status, response)
}

async function checkErrorsAndGetJson(status, response) {
  const statusGroup = status.charAt(0);
  if ('5' === statusGroup) {
    throw new Error(ERRORS.SERVER);
  }

  if ('409' === status) {
    throw new Error(ERRORS.VERSION_MINIMUM);
  }

  const json = await response.json();

  if ('4' === statusGroup) {
    throw new Error(JSON.stringify({
      statusCode: status,
      body: json,
    }));
  }

  return json;
}
