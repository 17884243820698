import {useState} from 'react';
import {apiClient, HEADER_TABLE_KEY, TABLE_NAME} from '../api/client';
import {Button, Col, Container, Form, Image, Row} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';

export default function Start() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const checkAndSave = async () => {
    setLoading(true);
    let pin = document.getElementById('input').value;

    try {
      const response = await apiClient.get(`/tables/${pin}`);
      localStorage.setItem(HEADER_TABLE_KEY, response.id);
      localStorage.setItem(TABLE_NAME, response.saleLocationName);
      setError(false);
      navigate('/');
    } catch (error) {
      localStorage.removeItem(HEADER_TABLE_KEY);
      localStorage.removeItem(TABLE_NAME);
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container fluid className="startContainer bgImage">
      <Row className="justify-content-center vh-100">
        <Col className="w-50 m-auto justify-content-center text-center main-screen" md={{ span: 4, offset: 4}}>
          <Image width={150} src="/images/logo.svg" className='logo mb-5' />
          <p className="text-white">Escribe el PIN de la mesa:</p>
          <Form.Control type="number" className="mb-3" id="input"/>
          <Button variant="primary" onClick={() => checkAndSave()}>Guardar</Button>
          {loading && <h5>Cargando...</h5>}
          {!loading && error && <h5 className="text-danger">Mesa incorrecta</h5>}
        </Col>
      </Row>
    </Container>
  )
}
