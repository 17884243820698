import {useDispatch, useSelector} from 'react-redux';
import {formatPrice} from '../helpers/helpers';
import {Button, Col, Row, Stack} from 'react-bootstrap';
import {reset} from '../store/cart';
import {useEffect, useState} from 'react';
import {apiClient, TABLE_NAME} from '../api/client';
import Item from '../components/cart/Item';
import {useNavigate, useParams} from 'react-router-dom';
import Loading from '../components/common/Loading';
import Swal from 'sweetalert2';

export default function Cart() {
  const stateCart = useSelector(state => state.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [ticket, setTicket] = useState();

  const cancelCart = () => {
    Swal.fire({
      icon: 'warning',
      title: '¿Seguro que quieres cancelar el carrito?',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      confirmButtonColor: '#181D2B',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(reset());
      }
    });
  };

  const submitCart = () => {
    navigate(`/catalog/${params.groupId}/category/${params.categoryId}/cart-summary`);
  };

  const fetch = async () => {
    setLoading(true);

    const responseTicket = await apiClient.get('/ticket');
    setTicket(responseTicket);

    setLoading(false);
  }

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Stack className="cart">
      {loading
        ? <Loading/>
        : <>
          <h3 className="text-uppercase mb-4">Pedido mesa {localStorage.getItem(TABLE_NAME)}</h3>

          {stateCart.cart.length === 0 && <h6>NO HAY NADA EN EL CARRITO</h6>}

          {stateCart.cart.map((element, idx) => {
            return <Item key={idx} element={element} idx={idx}/>
          })}

          {false === ticket?.isOpen && <h5 className="text-danger">Para poder completar el pedido debes avisar a alguien del staff.</h5>}

          {stateCart.cart.length > 0 &&
            <Row className="mt-auto">
              <Col xs={12} className="total">
                <hr/>
                <Row>
                  <Col>Total: {formatPrice(stateCart.priceTotal)}</Col>
                  <Col>Saldo mesa: {formatPrice(ticket?.balance)}</Col>
                </Row>
              </Col>
              <Col md={6}><Button className="text-uppercase w-100" variant="outline-primary"
                                  onClick={() => cancelCart()}>Cancelar</Button></Col>
              <Col md={6}><Button className="text-uppercase w-100" disabled={false === ticket?.isOpen}
                                  onClick={() => submitCart()}>Hacer pedido</Button></Col>
            </Row>
          }
        </>
      }
    </Stack>
  );
}
