import {NavLink, useParams} from 'react-router-dom';
import {Fragment} from 'react';
import {Stack} from 'react-bootstrap';

export default function CategoriesList({categories}) {
  const {groupId} = useParams();

  return (
    <Stack direction="horizontal" gap={4}>
      {categories.map((category) => {
        if (category.group.toString() === groupId) {
          return <NavLink key={category.id} to={`category/${category.id}`}
                          className={({isActive}) => isActive ? 'text-decoration-underline text-uppercase' : 'text-uppercase'}>{category.name}</NavLink>
        } else {
          return <Fragment key={category.id}></Fragment>
        }
      })}
    </Stack>
  )
}
