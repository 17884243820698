import {Button, Col, Row} from 'react-bootstrap';

export default function PinPad({pin, setPin}) {

  const insertNumber = (number) => {
    if (pin.length < 3) {
      setPin(pin + number.toString());
    }
  };
  const removeNumber = () => {
    setPin(pin.substring(0, pin.length - 1));
  };

  const iterate = () => {
    let numberElements = [];
    for (let number= 1; number <= 9; number++) {
      numberElements.push(<Col key={number} xs={4} className="number"><Button variant="outline-primary" onClick={() => insertNumber(number)}>{number}</Button></Col>);
    }
    numberElements.push(<Col key={10} xs={4} className="number"><Button variant="outline-primary" onClick={() => insertNumber(0)}>0</Button></Col>);
    numberElements.push(<Col key={11} xs={4} className="number"><Button variant="outline-primary" onClick={() => removeNumber()}>{'<<'}</Button></Col>);

    return numberElements;
  };

  return (
    <>
      <Row className="justify-content-end pin-pad mb-3">
        {iterate()}
      </Row>
      <Row>
        <Col className="text-center">
          <h3>{pin || <>- - -</>}</h3>
        </Col>
      </Row>
    </>
  )
}
