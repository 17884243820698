import {Container, Row, Col, Image} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import {apiClient, HEADER_TABLE_KEY} from '../api/client';
import {Link, Outlet, useMatch, useNavigate, useParams} from 'react-router-dom';
import Sidebar from '../components/catalog/Sidebar';
import Loading from '../components/common/Loading';
import CategoriesList from '../components/catalog/CategoriesList';
import Trolley from '../components/images/Trolley';

export default function Catalog() {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const matchCatalog = useMatch('/catalog/:groupId');
  const matchHistory = useMatch('/catalog/:groupId/history');
  const params = useParams();
  const navigate = useNavigate();

  const checkTable = () => {
    if (!localStorage.getItem(HEADER_TABLE_KEY)) {
      navigate('/start')
    }
  }

  const fetch = async () => {
    setLoading(true);

    const responseCategories = await apiClient.get('/catalog');
    setCategories(responseCategories);

    setLoading(false);

    let categoryId = null;
    responseCategories.forEach((category) => {
      if (category.group.toString() === params.groupId && null === categoryId) {
        categoryId = category.id;
      }
    });

    if (null !== matchCatalog && categoryId) {
      navigate(`/catalog/${params.groupId}/category/${categoryId}`);
    }
  }

  const setFirstCategory = async () => {
    let categoryId = null;

    categories.forEach((category) => {
      if (category.group.toString() === params.groupId && null === categoryId) {
        categoryId = category.id;
      }
    });

    if (categoryId) {
      navigate(`/catalog/${params.groupId}/category/${categoryId}`);
    }
  }

  const getGroupName = () => {
    switch (params.groupId) {
      case '1':
      default:
        return 'BOTELLAS';
      case '2':
        return 'COPAS';
      case '3':
        return 'COMIDA';
    }
  }

  useEffect(() => {
    checkTable();
    fetch();
  }, []);

  useEffect(() => {
    setFirstCategory();
  }, [params.groupId]);

  return (
    <Container fluid>
      <Row>
        <Sidebar/>
        <Col className="main d-flex flex-column" md={11}>
          <Row className="header bgImage justify-content-middle align-items-center" >
            <Col md={11}>
              <h1>{null === matchHistory ?
                `CARTA DE ${getGroupName()}`
                : 'HISTORIAL'
              }</h1>
            </Col>
            <Col md={1}>
              <Link to={`/catalog/${params.groupId}/category/${params.categoryId}/cart`} className="cart-icon">
                <Trolley/>
              </Link>
            </Col>
          </Row>
          <Row className="body-catalog" style={{ overflowY: 'auto'}}>
            {null === matchHistory &&
              <Col className="mb-4" xs={12}>
                {loading && <Loading/>}
                {!loading && <CategoriesList categories={categories}/>}
              </Col>
            }
            <Col xs={12}>
              {!loading && <Outlet/>}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
