import {Link} from 'react-router-dom';

export default function ProductCard({product}) {
  return (
    <Link className="product-card" to={`product/${product.id}`}>
      <div className="product-image" style={{backgroundImage: `url("data:image/jpeg;base64, ${product.image}")`}} />
      <p className="text-center text-uppercase g-2">{product.name}</p>
      <p className="text-center text-uppercase">{product.priceFormatted}</p>
    </Link>
  )
}
