import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  cart: [],
  priceTotal: 0,
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    reset: () => initialState,
    addToCart: (state, action) => {
      state.cart.push(action.payload);
      state.priceTotal += action.payload.product.price;
    },
    removeElement: (state, action) => {
      console.log(action.payload);
      state.cart.splice(action.payload, 1);

      let total = 0;
      state.cart.forEach((element) => {
        total += element.product.price;
      });
      state.priceTotal = total;
    }
  },
});

export const { addToCart, reset, removeElement } = cartSlice.actions;
export default cartSlice.reducer;
