import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {apiClient} from '../api/client';

export const orderCreate = createAsyncThunk('order/create', async (payload, {getState}) => {
  const state = getState();
  const cartToApi = {
    pin: payload,
    cart: [],
  };
  state.cart.cart.forEach((element) => cartToApi.cart.push({
    productId: element.product.id,
    additions: element.additionsSelected,
  }));

  return await apiClient.post(`/order`, cartToApi);
});

const initialState = {
  loading: false,
  finished: false,
  error: '',
  payment: null
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(orderCreate.pending, (state) => {
      state = {...initialState};
      state.loading = true;
      return state;
    });
    builder.addCase(orderCreate.fulfilled, (state, action) => {
      state = {...initialState};
      state.finished = true;
      return state;
    });
    builder.addCase(orderCreate.rejected, (state, action) => {
      state = {...initialState};
      state.finished = true;

      const json = JSON.parse(action.error.message);
      if ('400' === json.statusCode) {
        state.error = json.body.join(',');
      } else if ('403' === json.statusCode) {
        state.error = 'El pin es incorrecto';
      } else if ('402' === json.statusCode) {
        state.payment = json.body;
      }

      return state;
    });
  }
});

export const { reset } = orderSlice.actions;
export default orderSlice.reducer;
