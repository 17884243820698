import {useDispatch, useSelector} from 'react-redux';
import {Button, Col, Row, Stack} from 'react-bootstrap';
import {reset as cartReset} from '../store/cart';
import {reset as orderReset, orderCreate} from '../store/order';
import {useEffect, useState} from 'react';
import CartPayment from '../components/CartPayment';
import {TABLE_NAME} from '../api/client';
import {useNavigate} from 'react-router-dom';
import PinPad from '../components/cart/PinPad';
import Loading from '../components/common/Loading';

export default function CartSummary() {
  const stateCart = useSelector(state => state.cart);
  const stateOrder = useSelector(state => state.order);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pin, setPin] = useState('');

  const submitCart = () => {
    dispatch(orderCreate(pin)).then(() => {
      if (stateOrder.finished && '' === stateOrder.error && null === stateOrder.payment) {
        dispatch(cartReset);
      }
    });
  };

  useEffect(() => {
    dispatch(orderReset());
  }, []);

  return (
    <Stack className="cart-summary">
      <h3 className="text-uppercase">Resumen pedido mesa {localStorage.getItem(TABLE_NAME)}</h3>
      <hr className="mb-4"/>
        {stateCart.cart.length === 0 && <h6>NO HAY NADA EN EL CARRITO</h6>}
        {stateCart.cart.length > 0 &&
          <>
            <Row>
              {stateCart.cart.map((element, idx) => {
                return <Col key={idx}>
                  <h6>{element.product.name}</h6>
                  <h5>{element.product.priceFormatted}</h5>
                  <h6 className="text-secondary">
                    {Object.values(element.additionsSummary).map((summary, idx) => {
                      return <><span key={idx}>{summary.sum} x {summary.name}</span><br/></>
                    })}
                  </h6>
                </Col>
              })}
            </Row>
            <hr className="mb-3"/>
            <h5 className="mb-2">Introduzce el PIN de tu mesa a continuación para confirmar tu pedido. Revisa bien antes que los productos
              son los que deseas. Puedes continuar añadiendo más productos antes de terminar:</h5>
            <hr className="mb-4"/>
            <Row>
              {stateOrder.finished && '' === stateOrder.error && null === stateOrder.payment && <Col><h4 className="text-success">PROCESADO CORRECTAMENTE</h4></Col>}
              {stateOrder.finished && null !== stateOrder.payment && <CartPayment body={stateOrder.payment}/>}
              { (!stateOrder.finished || '' !== stateOrder.error) &&
                <>
                  <Col xs={6} className="border-end">
                    {stateOrder.loading && <Loading/>}
                    {stateOrder.finished && '' !== stateOrder.error && <h5 className="text-danger mb-5">{stateOrder.error}</h5>}
                    {!stateOrder.loading &&
                      <Button className="text-uppercase" variant="outline-primary" onClick={() => navigate('..', {relative: true})}>Volver</Button>
                    }
                  </Col>
                  <Col xs={6} className="text-center mb-3">
                    <PinPad pin={pin} setPin={setPin}/>
                    <Button disabled={3 !== pin.length} onClick={() => submitCart()}>Completar pedido</Button>
                  </Col>
                </>
              }
            </Row>
          </>
        }
    </Stack>
  );
}
